<template>
  <!--begin::Container-->
  <div v-if="!$apollo.loading" class="wrapper">
    <div id="printable">
      <div class="section-shaped my-0 skew-separator skew-mini ">
        <v-img
          class="page-header header-filter  header-small "
          max-height="250px"
          lazy-src="/media/biz-header.jpg"
          src="/media/test-biz-header.png"
          gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
        >
          <div class="container " style="padding-bottom: 65px;">
            <div class="md-layout justify-md-center ">
              <div
                class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
              >
                <div class="lato-header  font-weight-bolder text-white"></div>
                <div class="mt-5">
                  <h4 class="text-white "></h4>
                </div>
              </div>
            </div>
          </div>
        </v-img>
      </div>

      <div class="main main-raised" style="background:#fff;">
        <div class="container">
          <div class="text-lg-right">
            <v-btn elevation="9" rounded color="grey" @click="$router.go(-1)">
              <v-icon left>
                mdi-arrow-left
              </v-icon>
              Back
            </v-btn>
            <v-btn
              elevation="9"
              rounded
              color="primary"
              @click="$router.push('/market/return-order/' + $route.params.id)"
            >
              <v-icon left>
                mdi-package
              </v-icon>
              Return Order
            </v-btn>

            <v-btn elevation="9" rounded color="grey" @click="printInvoice">
              <v-icon left>
                mdi-printer
              </v-icon>
              Print
            </v-btn>
          </div>

          <div>
            <div class="row no-gutters mt-4">
              <div class="col-sm mr-1">
                <md-card class=" bg-info h-100">
                  <md-card-content>
                    <div class="text-white">Invoice #:</div>
                    <div class="text-white display-2">
                      {{ $route.params.id }}
                    </div>
                  </md-card-content>
                </md-card>
              </div>
              <div class="col-sm mr-1">
                <md-card class="bg-secondary h-100">
                  <md-card-content>
                    <div class="text-white">Sold To:</div>
                    <div class="text-white display-2">
                      {{ invoice.order.masterOrder.buyerCompany.company_name }}
                    </div>
                  </md-card-content>
                </md-card>
              </div>

              <div class="col-sm mr-1">
                <md-card class="bg-secondary h-100">
                  <md-card-content>
                    <div class="text-white">Customer PO #:</div>
                    <div class="text-white display-2">
                      {{ invoice.order.po }}
                    </div>
                  </md-card-content>
                </md-card>
              </div>

              <div class="col-sm mr-1">
                <md-card class="bg-secondary h-100">
                  <md-card-content>
                    <div class="text-white">Order #:</div>
                    <div class="text-white display-2">
                      {{ invoice.order.identifier }}
                    </div>
                  </md-card-content>
                </md-card>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="container">
              <div class="row ">
                <div class="col-lg-4">
                  <v-card class="card card-custom card-stretch">
                    <div class="card-header">
                      <div class="card-title">
                        <h3 class="card-label">Billing Info</h3>
                      </div>
                    </div>
                    <div class="card-body">
                      <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer</h5> -->

                      <p class="mb-2">
                        {{ invoice.order.billToAddress.company_name }}<br />
                        {{ invoice.order.billToAddress.address_line1 }}<br />
                        {{ invoice.order.billToAddress.address_line2 }}<br />
                        {{ invoice.order.billToAddress.city }}
                        {{ invoice.order.billToAddress.state }}
                        {{ invoice.order.billToAddress.zip_code }}
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-semibold mr-2">Phone:</span>
                        {{ invoice.order.billToAddress.phone }}
                      </p>
                    </div>
                  </v-card>
                </div>
                <!-- end col -->
                <div class="col-lg-4">
                  <v-card class="card card-custom card-stretch">
                    <div class="card-header">
                      <div class="card-title">
                        <h3 class="card-label">Shipping Info</h3>
                      </div>
                    </div>
                    <div class="card-body">
                      <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer Recieving</h5> -->

                      <p class="mb-2">
                        {{ invoice.order.shipToAddress.company_name }}<br />
                        {{ invoice.order.shipToAddress.address_line1 }}<br />
                        {{ invoice.order.shipToAddress.address_line2 }}<br />
                        {{ invoice.order.shipToAddress.city }}
                        {{ invoice.order.shipToAddress.state }}
                        {{ invoice.order.shipToAddress.zip_code }}
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-semibold mr-2">Phone:</span>
                        {{ invoice.order.shipToAddress.phone }}
                      </p>
                    </div>
                  </v-card>
                </div>
                <!-- end col -->

                <div class="col-lg-4">
                  <v-card flat>
                    <ul class="list-unstyled mb-0">
                      <li>
                        <p class="mb-2">
                          <span class="font-weight-semibold mr-2"
                            >Purchase Order #:</span
                          >
                          {{ invoice.order.po }}
                        </p>
                        <p class="mb-2">
                          <span class="font-weight-semibold mr-2"
                            >Payment Type:</span
                          >
                          {{ invoice.paymentType }}
                        </p>
                      </li>
                    </ul>
                  </v-card>
                </div>
                <!-- end col -->
              </div>

              <div class="row  ">
                <div class="col-lg-12">
                  <v-card class="card card-custom card-stretch">
                    <div class="card-header card-header-tabs-line">
                      <div class="card-title">
                        <h3 class="card-label">
                          Items Sold
                        </h3>
                      </div>
                      <div class="card-toolbar"></div>
                    </div>

                    <div class="card-body">
                      <!--begin: Datatable-->
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th class="text-left">Line ID</th>
                            <th>SKU</th>
                            <th>Thumb</th>
                            <th style="width:280px;">Item</th>
                            <th style="width:100px;">Qty Invoiced</th>
                            <th class="text-left">Price</th>
                            <th class="text-left">Subtotal</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, idx) in invoice.invoiceItems"
                            :key="idx"
                          >
                            <td>{{ item.orderItem.line }}</td>
                            <td>{{ item.sku }}</td>
                            <td>
                              <v-img
                                class="d-flex align-self-center mr-3 rounded-circle"
                                :src="
                                  item.originalItem.main_image != null
                                    ? baseurl + item.originalItem.main_image.url
                                    : '/media/rollerskate.png'
                                "
                                alt="Generic placeholder image"
                                width="50"
                                height="50"
                              >
                              </v-img>
                            </td>
                            <td>
                              {{ item.productName }}
                              <span v-if="item.name !== 'default'"
                                >({{ item.name }})</span
                              >
                            </td>
                            <td>
                              {{ item.quantity }}
                            </td>
                            <td>{{ item.unitPrice | dollar_format }}</td>
                            <td>
                              {{ item.lineTotal | dollar_format }}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="6" style="text-align: right;">
                              Subtotal
                            </td>
                            <td>{{ invoice.subtotal | dollar_format }}</td>
                          </tr>
                          <tr>
                            <td colspan="6" style="text-align: right;">
                              Shipping amount
                            </td>
                            <td>
                              {{ invoice.shiptotal | dollar_format }}
                            </td>
                          </tr>
                          <tr>
                            <td colspan="6" style="text-align: right;">
                              Total
                            </td>
                            <td>{{ invoice.total | dollar_format }}</td>
                          </tr>
                          <tr>
                            <td colspan="6" style="text-align: right;">
                              Platform Fee
                            </td>
                            <td>{{ invoice.platformFee | dollar_format }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <!--end: Datatable-->
                      <!-- <div class="text-lg-right">
                                        <v-btn @click="updateInvoice"> Update  </v-btn>
                                            </div> -->
                    </div>
                  </v-card>
                </div>
              </div>
              <!-- end row -->

              <!-- <div class="row mt-9">
                           
                        
                            <div class="col-lg-6">
                                <div class="card card-custom bg-light card-stretch gutter-b">
											<div class="card-header border-0">
												<h3 class="card-title font-weight-bold text-dark">Send Vendor Message</h3>
												<div class="card-toolbar">
													<div class="dropdown dropdown-inline" data-toggle="tooltip" title="" data-placement="left" data-original-title="Quick actions">
														<a href="#" class="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
															<i class="ki ki-bold-more-hor"></i>
														</a>
														<div class="dropdown-menu dropdown-menu-md dropdown-menu-right" style="">
															<ul class="navi navi-hover py-5">
																<li class="navi-item">
																	<a href="#" class="navi-link">
																		<span class="navi-icon">
																			<i class="flaticon2-drop"></i>
																		</span>
																		<span class="navi-text">New Group</span>
																	</a>
																</li>
																<li class="navi-item">
																	<a href="#" class="navi-link">
																		<span class="navi-icon">
																			<i class="flaticon2-list-3"></i>
																		</span>
																		<span class="navi-text">Contacts</span>
																	</a>
																</li>
																<li class="navi-item">
																	<a href="#" class="navi-link">
																		<span class="navi-icon">
																			<i class="flaticon2-rocket-1"></i>
																		</span>
																		<span class="navi-text">Groups</span>
																		<span class="navi-link-badge">
																			<span class="label label-light-primary label-inline font-weight-bold">new</span>
																		</span>
																	</a>
																</li>
																<li class="navi-item">
																	<a href="#" class="navi-link">
																		<span class="navi-icon">
																			<i class="flaticon2-bell-2"></i>
																		</span>
																		<span class="navi-text">Calls</span>
																	</a>
																</li>
																<li class="navi-item">
																	<a href="#" class="navi-link">
																		<span class="navi-icon">
																			<i class="flaticon2-gear"></i>
																		</span>
																		<span class="navi-text">Settings</span>
																	</a>
																</li>
																<li class="navi-separator my-3"></li>
																<li class="navi-item">
																	<a href="#" class="navi-link">
																		<span class="navi-icon">
																			<i class="flaticon2-magnifier-tool"></i>
																		</span>
																		<span class="navi-text">Help</span>
																	</a>
																</li>
																<li class="navi-item">
																	<a href="#" class="navi-link">
																		<span class="navi-icon">
																			<i class="flaticon2-bell-2"></i>
																		</span>
																		<span class="navi-text">Privacy</span>
																		<span class="navi-link-badge">
																			<span class="label label-light-danger label-rounded font-weight-bold">5</span>
																		</span>
																	</a>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
											<div class="card-body pt-2">
												<form class="form" id="kt_form_1">
													 
													<div class="form-group">
                                                        
                                                                  
                                             
                                                        
<div class="dropdown mb-10">
    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
Message Topic   </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" >Backorder</a>
        <a class="dropdown-item" >Tracking</a>
        <a class="dropdown-item" >Defect</a>
    </div>
</div>
                                                        
														<textarea class="form-control border-0" name="memo" rows="4" placeholder="Message"></textarea>
													</div>
													<div class="mt-10">
														<button class="btn btn-primary font-weight-bold">Send</button>
													</div>
												</form>
											</div>
										</div>
                            </div>
        
                            <div class="col-lg-6">
                                <div class="card card-custom card-stretch">
                                    
                                     <div class="card-header">
												<div class="card-title">
													<h3 class="card-label">Order Status  History</h3>
												</div>
change status 
                        <div>
                          <v-select
                            v-model="order_status"
                            :items="statusList"
                          ></v-select>
                          <v-btn @click="updateStatus"> update  </v-btn>
                        </div>
											</div>



										<v-timeline
        align-top
        dense
      >
        <v-timeline-item
          color="pink"
          small
          v-for="(log, index) in vendor_order_log" :key="index"
        >
          <v-row class="pt-1">
            <v-col cols="3">
              <strong>{{log.created_at | date_format}}</strong>
            </v-col>
            <v-col>
              <strong>{{log.detail}}</strong>
            </v-col>
          </v-row>
        </v-timeline-item>

      </v-timeline>
                                    
                               </div>
                            </div>
        
                        </div> -->
              <div v-show="false">
                <vue-html2pdf
                  ref="html2Pdf"
                  :show-layout="false"
                  :float-layout="false"
                  :enable-download="true"
                  :preview-modal="false"
                  :paginate-elements-by-height="1400"
                  filename="invoice"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"
                >
                  <section slot="pdf-content">
                    <print-invoice :invoice="invoice" />
                  </section>
                </vue-html2pdf>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import GetInvoiceDetails from '@/graphql/queries/GetInvoiceDetails.gql'
import VueHtml2pdf from 'vue-html2pdf'
import PrintInvoice from '@/components/supplier/PrintInvoice.vue'

export default {
  components: {
    VueHtml2pdf,
    PrintInvoice,
  },
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      })
      return formatter.format(value)
    },
  },
  data: () => ({
    baseurl: process.env.VUE_APP_STRAPI_API_URL || 'http://localhost:1337',
  }),
  apollo: {
    invoice: {
      query: GetInvoiceDetails,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
    },
  },
  methods: {
    printInvoice() {
      window.scrollTo(0, 0)
      this.$refs.html2Pdf.generatePdf()
    },
  },
}
</script>