<template>
  <div id="printable">
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <!-- <v-img
        class="page-header header-filter  header-small "
        max-height="250px"
        lazy-src="/media/biz-header.jpg"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      > -->
      <div class="container " style="padding-bottom: 65px;">
        <div class="md-layout justify-md-center ">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
          >
            <div class="lato-header  font-weight-bolder text-white"></div>
            <div class="mt-5">
              <h4 class="text-white ">Invoice</h4>
            </div>
          </div>
        </div>
      </div>
      <!-- </v-img> -->
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="container">
        <div>
          <div class="row no-gutters mt-4">
            <div class="col-sm mr-1">
              <md-card class=" bg-info h-100">
                <md-card-content>
                  <div class="text-white">Invoice #:</div>
                  <div class="text-white">
                    {{ $route.params.id }}
                  </div>
                </md-card-content>
              </md-card>
            </div>
            <div class="col-sm mr-1">
              <md-card class="bg-secondary h-100">
                <md-card-content>
                  <div class="text-white">Sold To:</div>
                  <div class="text-white">
                    {{ invoice.order.masterOrder.buyerCompany.company_name }}
                  </div>
                </md-card-content>
              </md-card>
            </div>

            <div class="col-sm mr-1">
              <md-card class="bg-secondary h-100">
                <md-card-content>
                  <div class="text-white">Customer PO #:</div>
                  <div class="text-white">
                    {{ invoice.order.po }}
                  </div>
                </md-card-content>
              </md-card>
            </div>

            <div class="col-sm mr-1">
              <md-card class="bg-secondary h-100">
                <md-card-content>
                  <div class="text-white">Order #:</div>
                  <div class="text-white">
                    {{ invoice.order.identifier }}
                  </div>
                </md-card-content>
              </md-card>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="container">
            <div class="row ">
              <div class="col-lg-4">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header">
                    <div class="card-title">
                      <h3 class="card-label">Billing Info</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer</h5> -->

                    <p class="mb-2">
                      {{ invoice.order.billToAddress.company_name }}<br />
                      {{ invoice.order.billToAddress.address_line1 }}<br />
                      {{ invoice.order.billToAddress.address_line2 }}<br />
                      {{ invoice.order.billToAddress.city }}
                      {{ invoice.order.billToAddress.state }}
                      {{ invoice.order.billToAddress.zip_code }}
                    </p>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2">Phone:</span>
                      {{ invoice.order.billToAddress.phone }}
                    </p>
                  </div>
                </v-card>
              </div>
              <!-- end col -->
              <div class="col-lg-4">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header">
                    <div class="card-title">
                      <h3 class="card-label">Shipping Info</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer Recieving</h5> -->

                    <p class="mb-2">
                      {{ invoice.order.shipToAddress.company_name }}<br />
                      {{ invoice.order.shipToAddress.address_line1 }}<br />
                      {{ invoice.order.shipToAddress.address_line2 }}<br />
                      {{ invoice.order.shipToAddress.city }}
                      {{ invoice.order.shipToAddress.state }}
                      {{ invoice.order.shipToAddress.zip_code }}
                    </p>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2">Phone:</span>
                      {{ invoice.order.shipToAddress.phone }}
                    </p>
                  </div>
                </v-card>
              </div>
              <!-- end col -->

              <div class="col-lg-4">
                <v-card flat>
                  <ul class="list-unstyled mb-0">
                    <li>
                      <p class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Purchase Order #:</span
                        >
                        {{ invoice.order.po }}
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Payment Type:</span
                        >
                        {{ invoice.paymentType }}
                      </p>
                    </li>
                  </ul>
                </v-card>
              </div>
              <!-- end col -->
            </div>

            <div class="row  ">
              <div class="col-lg-12">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header card-header-tabs-line">
                    <div class="card-title">
                      <h3 class="card-label">
                        Items Sold
                      </h3>
                    </div>
                    <div class="card-toolbar"></div>
                  </div>

                  <div class="card-body">
                    <!--begin: Datatable-->
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th class="text-left">Line ID</th>
                          <th>SKU</th>
                          <th>Thumb</th>
                          <th style="width:280px;">Item</th>
                          <th style="width:100px;">Qty Invoiced</th>
                          <th class="text-left">Price</th>
                          <th class="text-left">Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, idx) in invoice.invoiceItems"
                          :key="idx"
                        >
                          <td>{{ item.orderItem.line }}</td>
                          <td>{{ item.sku }}</td>
                          <td>
                            <v-img
                              class="d-flex align-self-center mr-3 rounded-circle"
                              :src="
                                item.originalItem.main_image != null
                                  ? baseurl + item.originalItem.main_image.url
                                  : '/media/rollerskate.png'
                              "
                              alt="Generic placeholder image"
                              width="50"
                              height="50"
                            >
                            </v-img>
                          </td>
                          <td>
                            {{ item.productName }}
                            <span v-if="item.name !== 'default'"
                              >({{ item.name }})</span
                            >
                          </td>
                          <td>
                            {{ item.quantity }}
                          </td>
                          <td>{{ item.unitPrice | dollar_format }}</td>
                          <td>
                            {{ item.lineTotal | dollar_format }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="6" style="text-align: right;">
                            Subtotal
                          </td>
                          <td>{{ invoice.subtotal | dollar_format }}</td>
                        </tr>
                        <tr>
                          <td colspan="6" style="text-align: right;">
                            Shipping amount
                          </td>
                          <td>
                            {{ invoice.shiptotal | dollar_format }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="6" style="text-align: right;">
                            Total
                          </td>
                          <td>{{ invoice.total | dollar_format }}</td>
                        </tr>
                        <tr>
                          <td colspan="6" style="text-align: right;">
                            Platform Fee
                          </td>
                          <td>{{ invoice.platformFee | dollar_format }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <!--end: Datatable-->
                    <!-- <div class="text-lg-right">
                                        <v-btn @click="updateInvoice"> Update  </v-btn>
                                            </div> -->
                  </div>
                </v-card>
              </div>
            </div>

            <vue-html2pdf
              ref="html2Pdf"
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="true"
              :paginate-elements-by-height="1400"
              filename="invoice"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              pdf-orientation="portrait"
              pdf-content-width="800px"
            >
              <section slot="pdf-content">
                <print-invoice :invoice="invoice" />
              </section>
            </vue-html2pdf>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      })
      return formatter.format(value)
    },
  },
  props: {
    invoice: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    baseurl: process.env.VUE_APP_STRAPI_API_URL || 'http://localhost:1337',
  }),
}
</script>
